import React from "react"
import Helmet from 'react-helmet'



function CenterContainer(props) {
   return <div><div style={{   display: "flex", justifyContent: "center", alignItems: "center"}}>
    {props.children}
  </div></div>
}



function Wrap(props) {
  return <CenterContainer>
          
            <div className="wrap"><div style={props.style}>
                {props.children}
            </div></div>
          </CenterContainer>
}



function ImgCenter(props) {
  return <div>
            <CenterContainer>
        <img src={props.src} alt="" className="imageImg" style={props.style} />
        </CenterContainer>
 </div>
}


function ImgScreenshot(props) {
  return <><div className="screenshotFrame">
            <CenterContainer>
        <img src={props.src} alt="" className="screenshotImg" />
        <div className="hiddenHover">show the description</div>
        </CenterContainer>
 </div>
 </>
}

function ImgScreenshot_With_Description(props) {
  return <>
            <CenterContainer>
        <img src={props.src} alt="" className="screenshotImgDescription" />
        </CenterContainer>
        <CenterContainer>
        <p className="ImgDescription">{props.description}</p>
        </CenterContainer>


 </>
}


function PostWrap(props) {
  return <CenterContainer>
          
            <div className="PostWrap"><div style={props.style}>
                {props.children}
            </div></div>
          </CenterContainer>
}



function SiteTitle(props) {
  return  <Helmet>
  <title>{props.string}</title>
  </Helmet>
}




export {CenterContainer, Wrap, ImgCenter, PostWrap, ImgScreenshot, ImgScreenshot_With_Description, SiteTitle }