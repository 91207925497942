
 
import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'
 
class App extends Component {
  render() {
    return (
        <Mailchimp
        action= "https://glazial.us3.list-manage.com/subscribe/post?u=e5ad3061a1f1ac90511549a4c&amp;id=5fd742e14a"
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
        ]}
        />
    );
  }
}
 
export default App;
