import React from 'react'
import { Link } from 'gatsby'
import './footer/footer.css'
import Mailchimp from "../components/apps/mailchimp_subscribe"


import glazial_logo from '../images/main/glazial_white.png'




// gesicht
import './navigation.css'
import './layout.scss'
import { CenterContainer } from './basics';




const Layout = ({ children }) => (

      <>

        <nav>
        <CenterContainer>

        <ul  id="navigation" style={{top: "0px"}}>
            <Link to="/holonoptic"  activeClassName="navlink">/--</Link>
            <Link to="/holonoptic/#download">/DOWNLOAD</Link>
            <Link to="/holonoptic/world" activeClassName="navlink">/WORLD</Link>
            <Link to="/holonoptic/media" activeClassName="navlink">/MEDIA</Link>
        </ul>
        </CenterContainer>

        </nav>

        <div className="page-wrap" style={{minHeight:"100vh", marginBottom: "-120px" }}>

          {children}
          </div>

          <div style={{height:"11vh"}}></div>
          <div style={{height:"10vh"}}></div>


          <footer style={{
              width: "100%", 
              bottom: "0", 
              height: "120px",
              borderTop: "1px solid #77d2ff"
        }}>



                <CenterContainer>

                    <div style={{marginRight: "10vw"}}>
                    <h3>Release notification</h3>
                        <Mailchimp />
                    </div>

                    <Link to="/">
                        <img  src={glazial_logo} alt="" className="glazial_logo_glow" style={{width: "200px", padding: "20px"}} />
                    </Link>
                </CenterContainer>
          </footer>

      </>

)



export default Layout
