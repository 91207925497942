import React from 'react'

import Gallery from 'react-grid-gallery';

import './gallery.css'




// IMAGES



import construction2 from '../../images/screenshots/construction.jpg'
import emotion2 from '../../images/screenshots/emotions2.jpg'
import map4 from '../../images/screenshots/map4.jpg'
import projects1 from '../../images/screenshots/project.jpg'
import molecule3 from '../../images/screenshots/molecule.jpg'
import server1 from '../../images/screenshots/server.jpg'
import molecule2 from '../../images/screenshots/molecule2.jpg'
import cubicles1 from '../../images/screenshots/cubicles1.jpg'
import inventory1 from '../../images/screenshots/inventory.jpg'
import self1 from '../../images/screenshots/self.jpg'
import desktop1 from '../../images/screenshots/desktop.jpg'




const IMAGES =
[
    {
        src: construction2,
        thumbnail: construction2,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },

    {
        src: emotion2,
        thumbnail: emotion2,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },



    {
        src: map4,
        thumbnail: map4,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },

    {
        src: projects1,
        thumbnail: projects1,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },

    {
        src: molecule3,
        thumbnail: molecule3,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },

    {
        src: server1,
        thumbnail: server1,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },




    {
        src: cubicles1,
        thumbnail: cubicles1,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },



    {
        src: molecule2,
        thumbnail: molecule2,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },


    {
        src: inventory1,
        thumbnail: inventory1,
        thumbnailWidth: 355,
        thumbnailHeight: 200,
        caption: ""
    },





{
    src: self1,
    thumbnail: self1,
    thumbnailWidth: 355,
    thumbnailHeight: 200,
    caption: ""
},

{
src: desktop1,
thumbnail: desktop1,
thumbnailWidth: 355,
thumbnailHeight: 200,
caption: ""
},



]

export default () =>
<>



<Gallery images={IMAGES} enableImageSelection={false} />



</>
