import React from 'react'


import Layout from '../../components/layout'
import {Wrap, SiteTitle} from "../../components/basics"


import LargeGallery from "../../components/gallery/screenshots_full"



// videos

import trailer from '../../images/Trailer.mp4'
import story from '../../images/Story.mp4'



export default () =>
<>
<SiteTitle string="holonoptic™ | screenshots | videos" />


  <Layout>
        <Wrap>
          <div className="media">


            <h2>Screenshots</h2>
            <LargeGallery />
            <div style={{clear:"both"}}></div>



            <p style={{fontSize:"0.2px"}}>- </p>


            <h2>Videos</h2>
                        <p>Recruiting video</p>

                        <video controls width="100%">
                        <source src={trailer} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>




                        <p>Training video</p>

                        <video controls width="100%">
                        <source src={story} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>




            </div>


            <div style={{height:"4vw"}} />


        </Wrap>
  </Layout>
</>
